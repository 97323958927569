import React from 'react'
import cx from 'classnames'
import { array } from 'helpers'
import { useDevice } from 'device'

import { getRecommendedProductCnstrcAttrs } from 'modules/constructorIO'

import type { Label } from 'typings/graphql'

import { ScrollableContainer } from 'components/layout'
import { Carousel } from 'components/dataDisplay'

import ProductCardSmall, { type ProductCardSmallProps } from 'compositions/productCards/ProductCardSmall/ProductCardSmall'
import ProductCardSmallSkeleton from 'compositions/productCards/ProductCardSmallSkeleton/ProductCardSmallSkeleton'

import ProductCardDetailed, { type ProductCardDetailedProps } from 'compositions/productCards/ProductCardDetailed/ProductCardDetailed'
import ProductCardDetailedSkeleton from 'compositions/productCards/ProductCardDetailedSkeleton/ProductCardDetailedSkeleton'
import type { AddToButtonPropsWithoutProduct } from 'compositions/buttons/AddToButton/AddToButton'


type Products = {
  desktopType?: 'small'
  products: ProductCardSmallProps['data'][]
} | {
  desktopType?: 'detailed'
  products: ProductCardDetailedProps['data'][]
}

type ProductCarouselBaseProps = {
  className?: string
  excludeLabels?: Label[]
  extraNode?: React.ReactNode
  isEcommerce?: boolean
  isFetching?: boolean
  withoutNotes?: boolean
  withoutLink?: boolean
  lazy?: boolean
  bgColorClassName?: 'bg-light-beige' | 'bg-white'
  dataAttributes?: Record<string, any>
  recommenderStrategies?: Record<string, string>
  onProductLinkClick?: ProductCardDetailedProps['onProductLinkClick'] | ProductCardSmallProps['onProductLinkClick']
} & Products


export type ProductCarouselProps = ProductCarouselBaseProps & ({
  buttonProps: AddToButtonPropsWithoutProduct
} | {
  metadata: QueueModule.AddItemInput['metadata']
  withNonAuthRedirect?: boolean
  onProductButtonClick?: (data: ProductCardSmallProps['data']) => void
})

const ProductCarousel: React.FunctionComponent<ProductCarouselProps> = (props) => {
  const { className, desktopType = 'small', products, excludeLabels, extraNode,
    isEcommerce, isFetching, withoutNotes, withoutLink, lazy, bgColorClassName,
    dataAttributes, recommenderStrategies, onProductLinkClick } = props
  const { isMobile } = useDevice()

  const isTypeSmall = desktopType === 'small'

  const [ SkeletonComponent, ProductComponent, visibleNodeAmount ] = isTypeSmall || isMobile
    ? [ ProductCardSmallSkeleton, ProductCardSmall, 6 ]
    : [ ProductCardDetailedSkeleton, ProductCardDetailed, 3 ]

  let itemsNode

  if (isFetching) {
    itemsNode = array.range(1, visibleNodeAmount).map((index) => (
      <SkeletonComponent key={index} />
    ))
  }
  else if (!products?.length) {
    return null
  }
  else {
    itemsNode = products.map((data) => {
      const { uid } = data

      const attributes = getRecommendedProductCnstrcAttrs({
        recommenderStrategies,
        uid,
      })

      return (
        <ProductComponent
          className="h-full"
          key={data.id}
          data={data}
          excludeLabels={excludeLabels}
          isEcommerce={isEcommerce}
          withoutNotes={withoutNotes}
          withoutLink={withoutLink}
          bgColorClassName={bgColorClassName}
          buttonProps={'buttonProps' in props ? props.buttonProps : {
            withNonAuthRedirect: props.withNonAuthRedirect,
            onClick: props.onProductButtonClick ? () => props.onProductButtonClick(data) : undefined,
            metadata: props.metadata,
          }}
          lazy={lazy}
          onProductLinkClick={onProductLinkClick}
          dataAttributes={attributes}
        />
      )
    })
  }

  if (isMobile) {
    return (
      <ScrollableContainer className="-m-16 flex">
        <div className={cx(className, 'grid auto-cols-[216rem] grid-flow-col gap-16 py-16')} {...dataAttributes}>
          {itemsNode}
        </div>
        {extraNode}
      </ScrollableContainer>
    )
  }

  if (itemsNode.length > visibleNodeAmount) {
    return (
      <Carousel
        viewportClassName="-m-8 p-8"
        // this -13rem values are calculated as gap * (cols - 1) / cols
        containerClassName={cx(isTypeSmall ? 'auto-cols-[calc(100%/6-16rem*5/6)] gap-16' : 'auto-cols-[calc(100%/3-16rem*2/3)] gap-16')}
        buttons={{
          prevClassName: cx('-left-8 min-[1280px]:-left-56'),
          nextClassName: cx('-right-8 min-[1280px]:-right-56'),
        }}
        withButtons
        slidesToScroll={visibleNodeAmount}
        dataAttributes={dataAttributes}
      >
        {itemsNode}
      </Carousel>
    )
  }

  return (
    <div
      className={cx(className, isTypeSmall ? 'auto-cols-[minmax(auto,176rem)]' : 'auto-cols-[minmax(auto,368rem)]', 'grid grid-flow-col justify-center gap-16')}
      {...dataAttributes}
    >
      {itemsNode}
    </div>
  )
}


export default React.memo(ProductCarousel)
