import React, { useEffect } from 'react'
import { track } from 'analytics'
import { useDevice } from 'device'
import { useEntry } from 'intersection-observer'

import { useDiscoveryQuizResult } from 'modules/recommendations'

import { Text, type TextStyle } from 'components/dataDisplay'

import ProductCarousel from 'compositions/productLists/ProductCarousel/ProductCarousel'


const PLACEMENT = 'Quiz recommendations'

type RecommendationsFeedProps = {
  className?: string
  productsContainerClassName?: string
  title: Intl.Message | string
  titleStyle?: TextStyle
  type: 'small' | 'detailed'
  limit?: number
  withNotification?: boolean
  withUpgradeFlow?: boolean
  productIdsToExclude?: number[]
}

const RecommendationsFeed: React.FunctionComponent<RecommendationsFeedProps> = (props) => {
  const { className, productsContainerClassName, title, limit = 12, type, withNotification, withUpgradeFlow, productIdsToExclude } = props

  const { isMobile } = useDevice()

  const [ ref, entry ] = useEntry({ once: true, observerProps: { rootMargin: '50%' } })
  const skip = !entry?.isIntersecting

  const { quizRecommendations, isFetching } = useDiscoveryQuizResult({
    profileRecommendationInput: {
      limit,
      placement: 'Thank you page',
    },
    ssr: false,
    skip,
  })

  const { recommenderName } = quizRecommendations || {}

  useEffect(() => {
    if (!recommenderName) {
      return
    }

    track('Recommendations display', {
      placement: PLACEMENT,
      recommenderName,
    })
  }, [ recommenderName ])

  const products = quizRecommendations?.products || []
  const filteredProducts = productIdsToExclude?.length > 0 ? products.filter((product) => !productIdsToExclude.includes(product.id)) : products

  const titleStyle = props.titleStyle || (isMobile ? 'h5' : 'h4')

  return (
    <div className={className} ref={ref}>
      <Text
        className="mb-32 text-center"
        message={title}
        style={titleStyle}
        html
      />
      <ProductCarousel
        className={productsContainerClassName}
        desktopType={type}
        products={filteredProducts}
        isFetching={isFetching}
        withoutLink
        buttonProps={{
          metadata: {
            placement: PLACEMENT,
            recommenderName,
          },
          withNotification,
          withUpgradeFlow,
        }}
      />
    </div>
  )
}


export default RecommendationsFeed
